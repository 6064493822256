import { render, staticRenderFns } from "./ImagesListDetails.vue?vue&type=template&id=560176a7&scoped=true&"
import script from "./ImagesListDetails.vue?vue&type=script&lang=js&"
export * from "./ImagesListDetails.vue?vue&type=script&lang=js&"
import style0 from "./ImagesListDetails.vue?vue&type=style&index=0&id=560176a7&scoped=true&lang=css&"
import style1 from "./ImagesListDetails.vue?vue&type=style&index=1&id=560176a7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560176a7",
  null
  
)

export default component.exports