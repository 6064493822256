export const isAuthenticated = state => state.isAuthenticated;
export const user = state => state.user;
export const token = state => state.token;
export const list_id = state => state.list_id;
export const isCollapse = state => state.isCollapse;
export const sickNess1 = state => state.sickNess1;
export const index = state => state.index;
export const idx = state => state.idx;
export const input_name = state => state.input_name;
export const hot_name = state => state.hot_name;
export const crumbsarr = state => state.crumbsarr;
export const barckArr = state => state.barckArr;
export const retrievalArr = state => state.retrievalArr;
export const stateurl = state => state.stateurl;
export const cdssInfo = state => state.cdssInfo;
export const is_pom = state => state.is_pom;
export const headerNavItems = state => state.headerNavItems;
